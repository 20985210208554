<template>
  <div class="filter-position">
    <slot name="ContentSideBarButtons">
    </slot>
    <quick-sidebar>
      <template slot="Content">
        <div class="content-filter">
          <div v-for="(filter, index) in value" v-bind:key="index">
            <!-- Filtro Colaborador -->
            <div v-if="index === 'employees'" class="mb-3">
              <label>{{$t('form.employee.label')}}</label>
              <multiselect
                v-model="formData.filEmployees"
                :id-field="'id_employee'"
                key="id"
                :multiple="true"
                :max="30"
                track-by="label"
                label="label"
                :allow-empty="true"
                placeholder="Selecione um ou mais"
                :loading="isLoadingSearch"
                :close-on-select="false"
                :clear-on-select="true"
                :options="employeeList"
                :show-labels="false"
                @search-change="employeeFetchOptions"
              >
                <template slot="noOptions">
                  Digite para pesquisar
                </template>
                <template slot="noResult">
                  Ops! Nenhum resultado encontrado
                </template>
              </multiselect>
            </div>

            <!-- Cliente -->
            <div v-if="index === 'customer'" class="mb-3">
              <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" v-model="formData.filCliente" />
            </div>

            <!-- Cliente - Não Filtrado -->
            <div v-if="index === 'unfiltered_customers'" class="mb-3">
              <label>{{$t('form.customer.label')}}</label>
              <multiselect
                v-model="formData.filClienteNaoFiltrado"
                :id-field="'id_unfiltered_customers'"
                key="id"
                :multiple="true"
                :max="30"
                track-by="label"
                label="label"
                :allow-empty="true"
                placeholder="Selecione um ou mais"
                :loading="isLoadingSearch"
                :close-on-select="false"
                :clear-on-select="true"
                :options="unfilteredCustomerList"
                :show-labels="false"
                @search-change="unfilteredCustomerFetchOptions"
              >
                <template slot="noOptions">
                  Digite para pesquisar
                </template>
                <template slot="noResult">
                  Ops! Nenhum resultado encontrado
                </template>
              </multiselect>
            </div>

            <!-- Filtro Processo -->
            <div v-if="index === 'internal_code'" class="mb-3">
              <div class="md-form mb-0">
                <input id="internal_code" name="internal_code" ref="internal_code"
                       :title="$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                       v-model="formData.filProcesso">
                <label :class="{'active': formData.filProcesso}">{{ $i18n.t('form.process.internal_code') }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="actions-filter">
          <div class="py-1 text-center">
            <div class="md-form mb-0">
              <div class="row">
                <div class="col-6">
                  <button type="button" v-on:click="applyFilter" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                  </button>
                </div>
                <div class="col-6">
                  <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                      $t('filter.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </quick-sidebar>
  </div>
</template>

<script>
// Services
import EmployeeService from '@/services/EmployeeService'
import CustomerService from '@/services/CustomerService'

// Components
import QuickSidebar from '@/components/QuickSidebar.vue'
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'FilterSideBarNew',
  components: {
    QuickSidebar,
    MultipleSelect,
    Multiselect
  },
  data () {
    return {
      isLoadingSearch: false,
      employeeList: [],
      customerList: [],
      unfilteredCustomerList: [],
      formData: {
        filEmployees: [],
        filCliente: [],
        filProcesso: null
      },
      filter: {}
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    refName: {
      type: String,
      required: false,
      default: 'vuetable'
    }
  },
  methods: {
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (query) {
      let _this = this

      if (query) {
        _this.isLoadingSearch = true

        let filter = {
          searchTerm: query
        }

        EmployeeService.getFuncionarios(filter).then(res => {
          _this.employeeList = []
          _this.employeeList = res.data.data
          _this.isLoadingSearch = false
        })
      } else {
        _this.employeeList = []
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    unfilteredCustomerFetchOptions (query) {
      let _this = this

      if (query) {
        _this.isLoadingSearch = true

        let filter = {
          searchTerm: query,
          checkUserAccess: false
        }

        CustomerService.getCustomers(filter).then(res => {
          _this.unfilteredCustomerList = []
          _this.unfilteredCustomerList = res.data.data
          _this.isLoadingSearch = false
        })
      } else {
        _this.unfilteredCustomerList = []
      }
    },
    buildFilters () {
      if (this.value.hasOwnProperty('customer')) {
        this.value.customer = (this.formData.filCliente) ? this.formData.filCliente.map(x => (x.id)) : []
      }

      if (this.value.hasOwnProperty('internal_code')) {
        if (this.formData.filProcesso) {
          this.value.internal_code = this.formData.filProcesso
        } else {
          this.value.internal_code = null
        }
      }

      if (this.value.hasOwnProperty('id_status')) {
        this.value.id_status = 1
      }

      if (this.value.hasOwnProperty('employees')) {
        this.value.employees = (this.formData.filEmployees) ? this.formData.filEmployees.map(x => (x.id)) : []
      }

      if (this.value.hasOwnProperty('unfiltered_customers')) {
        this.value.unfiltered_customers = (this.formData.filClienteNaoFiltrado) ? this.formData.filClienteNaoFiltrado.map(x => (x.id)) : []
      }
    },
    resetFilter () {
      this.formData.filProcesso = null
      this.formData.filReferenciaCliente = null
      this.formData.filHBL = null

      this.formData.filPeriodoEmbarqueDtInicio = null
      this.formData.filPeriodoEmbarqueDtFim = null
      this.formData.filPeriodoDesembarqueDtInicio = null
      this.formData.filPeriodoDesembarqueDtFim = null
      this.formData.filCliente = []
      this.formData.filEmployees = []

      this.buildFilters()

      let filters = Object.assign({}, this.formData)

      this.$store.dispatch('setAppliedFilter', filters)

      this.$emit('input', this.value)
      this.hideQuichSidebar()
      this.$emit('apply-filter', this.value)
    },
    applyFilter () {
      this.employeeList = []
      this.customerList = []

      // Copia dados para variável, para que filtros aplicados, sejam atualizados apenas quando o usuário clicar em "Filtar" ou "Limpar"
      let filters = Object.assign({}, this.formData)

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', filters)

      this.$emit('input', this.value)
      this.hideQuichSidebar()
      this.$emit('apply-filter', this.value)
    },
    showQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.add('has-backdrop')
      sideBar.classList.add('shined')
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      if (sideBar) {
        document.body.classList.remove('has-backdrop')
        sideBar.classList.remove('shined')
      }
    }
  },
  computed: {
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.formData = this.$store.getters.aplliedFilters || null
        this.applyFilter()
      }
    }
  },
  beforeMount () {
    if (this.$Cookie.get('_userStoredFilters_')) {
      this.formData = JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      this.applyFilter()
    } else {
      this.formData = this.$store.getters.aplliedFilters || null
      this.applyFilter()
    }
  }
}
</script>

<style scoped>
  .content-filter {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
  .actions-filter {
    height: 58px;
    min-height: 58px;
  }

  .filter-position {
    margin-top: -50px;
  }
  @media (min-width: 350px) and (max-width: 475px) {
    .filter-position {
      margin-top: 0px !important;
    }
    .filter-position ul {
      min-width: inherit;
    }
  }
</style>
